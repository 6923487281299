// @flow
import { getElement } from '../common/get/getElement'
import { getAllControls } from './RegistrationGetters'
import {
  updateChecked,
  redoSearch,
  hideSearchResults,
  showSearchResults,
  throttledValidate,
  hideFadeAtBottom,
  showSchoolSearch,
  showPopulatedSchoolSearch,
  hidePopulatedSchoolSearch,
  isASchoolSelected,
  enableSearchResultNextButton,
  removePopulatedClass,
  removePopulatedInstitutionClass,
  disableStateField,
  enableStateField,
  toggleStateField,
  resetInstitutionModal,
  updateSchoolInputFormPlaceholderText
} from './RegistrationPresenters'
import { fetchSchools } from './RegistrationFetchers'
import { next, submitInstitutionModal } from '../Registration'
import $ from 'jquery'
import { isPopulatedStepTwo } from './RegistrationValidators'
import { getSchoolSearchFormData } from '../common/CommonFetchers'

export const addValidationListeners = () => {
  getAllControls().map(control => {
    control.addEventListener('keyup', onSchoolSelectKeyup)
    control.addEventListener('change', onSchoolSelectChange)
  })
}

const onSchoolSelectKeyup = () => {
  throttledValidate()
}

const onSchoolSelectChange = () => {
  throttledValidate()
  updateSchoolInputFormPlaceholderText() // This is only necessary for "onChange" since the school mode is only handled by radio buttons.
}

export const addChooseSchoolListener = () => {
  $(document).on('click', '[data-school-result]', updateChecked)
}

export const addSearchResultButtonClickListener = () => {
  $(document).on('click', '[data-search-result-btn]', e => {
    const action = e.target.dataset.searchResultBtn
    console.log(action)
    if (action === 'next') {
      searchResultsNextButtonListener(e)
    }

    if (action === 'back') {
      searchResultsBackButtonListener(e)
    }
  })
}

export const addPopulatedResultButtonClickListener = () => {
  $(document).on('click', '[data-populated-result-btn]', e => {
    const action = e.target.dataset.populatedResultBtn
    if (action === 'next') {
      populatedResultNextBtnListener(e)
    }

    if (action === 'back') {
      populatedResultBackBtnListener(e)
    }

    if (action === 'redo') {
      redoSearch(e)
    }
  })
}

const addBackButtonListener = () => {
  $(document).on('click', '[data-action="back"]', backBtnListener)
}

export const addSearchResultsListeners = () => {
  addBackButtonListener()
  addChooseSchoolListener()
  addSearchButtonListener()
  addSearchResultButtonClickListener()
  addPopulatedResultButtonClickListener()
}

export const addSearchButtonListener = () => {
  $(document).on('click', '[data-action="search"]', () => {
    const formData = getSchoolSearchFormData()
    fetchSchools(formData).then(schools => {
      showSearchResults({ schools, formData })
      hideFadeAtBottom(
        getElement('[data-search-results]'),
        getElement('[data-search-container]')
      )
    })
  })
}

export const addInstitutionModalListeners = () => {
  $(document).on('submit', `form[data-form-name="institution"]`, e => {
    e.preventDefault()
    submitInstitutionModal(e)
    toggleStateField()
  })
}

const searchResultsBackButtonListener = () => {
  hideSearchResults()
  showSchoolSearch()
}

const searchResultsNextButtonListener = () => {
  hideSearchResults()
  showPopulatedSchoolSearch()
  resetInstitutionModal()
}

const backBtnListener = () => {}

const populatedResultBackBtnListener = () => {
  hidePopulatedSchoolSearch()
  showSearchResults()
  if (isASchoolSelected()) {
    enableSearchResultNextButton()
  }
  removePopulatedClass()
  removePopulatedInstitutionClass()
}

const populatedResultNextBtnListener = e => {
  hidePopulatedSchoolSearch()
  next(e)
}

export const allButtonListeners = () => {
  $(document).on('click', $('.registration button'), () => {
    if (isPopulatedStepTwo()) {
      disableStateField()
    } else {
      enableStateField()
    }
    toggleStateField()
  })
}
