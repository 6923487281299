import $ from 'jquery'
import {
  showSection,
  hideError,
  isEmail,
  isValidName,
  formToObject,
  getListOfValues,
  formAppendArray
} from './common/common'
import QuickModal from './QuickModal'
import Form from './Form'
import CauliflowerModal from './CauliflowerModal'
import NotificationBanner from './NotificationBanner'
import initializeSchoolSearch from './registration/RegistrationInitializer'
import {
  isPopulatedStepTwo,
  isPopulatedInstitution
} from './registration/RegistrationValidators'
import { getElement } from './common/get/getElement'
import {
  showPopulatedSchoolSearch,
  showSchoolSearch,
  showPopulateInstitute
} from './registration/RegistrationPresenters'

const CONTEXT = window.CONTEXT || ''

var step = 0 // TODO: Remove "Step" paradigm and replace with more robust "data-action="goToSchoolSearch" "

var init = function () {
  QuickModal.addCloseAlert('registration-alt-form', altFormClosed)

  attachRegistrationListeners()

  // TODO: Remove -- this is for testing ONLY
  initializeDevTools()

  initializeSchoolSearch()
}

const attachRegistrationListeners = () => {
  $('#registration .back-btn').on('click', back)
  $('#registration .next-btn').on('click', next)
  $('#registration .finish-btn').on('click', submitRegForm)
  $('#registration form').submit(submitRegForm)
  $('.modal').on('click', '.reg-tou .accept-btn', TOUAccepted)
}

const initializeDevTools = () => {
  window.__ = {
    DEV_POP_FORM,
    DEV_DISABLE_VALIDATION,
    DEV_TOGGLE_VALIDATION,
    DEV_SHOW_STEP,
    DEV_SHOW_NOTIFICATION,
    showSection
  }
}

var show = function () {
  step = 1
  updateRegForm()
}

var back = function (e) {
  e.preventDefault()

  if (step === 1) {
    history.pushState(
      '',
      document.title,
      window.location.pathname + window.location.search
    )
    showSection('login')
    return
  }

  hideError()
  if (step > 1) {
    step--
  }

  updateRegForm()
}

export var next = function (e) {
  e.preventDefault()

  if (!stepValidated()) {
    if (step === 2) {
      updateRegForm()
    }

    return false
  }

  if (step === 1 && !DEV_DISABLE_VALIDATION) {
    $.ajax({
      url: CONTEXT + '/api/v1/user/validateEmail',
      data: {
        emailAddress: $('section.registration input[name="email"]').val()
      },
      method: 'POST',
      success: checkEmailResponse
    })

    return
  }

  if (step < 3) {
    step++
    updateRegForm()
  }
}

var checkEmailResponse = function (data) {
  console.log(data)
  if (data.success === true) {
    step++
    updateRegForm()
  } else {
    NotificationBanner.open(data.message, 'error')
  }
}

var stepValidated = function () {
  if (DEV_DISABLE_VALIDATION) {
    return true
  }

  Form.hideValidationForForm('registration')

  function findInput (step, inputClass) {
    return $('#registration .step-' + step + ' .' + inputClass + ' input')
  }
  // Step One Inputs
  var $emailInput = findInput('one', 'email')
  var $emailConfirmInput = findInput('one', 'email-confirm')
  var $passwordInput = findInput('one', 'password')
  var $passwordConfirmInput = findInput('one', 'password-confirm')

  // Step Two Inputs
  var $firstNameInput = findInput('two', 'first-name')
  var $lastNameInput = findInput('two', 'last-name')

  // Step Three Inputs
  var $selectedRoles = $('#registration .roles').find('.selected')
  var $selectedGrades = $('#registration .grades-row').find('.selected')

  switch (step) {
    case 1:
      if (!isEmail($emailInput.val())) {
        Form.showErrorForField(
          'registration',
          'email',
          'Please enter a valid email address.'
        )
        return false
      }
      if ($emailInput.val() !== $emailConfirmInput.val()) {
        Form.showErrorForField(
          'registration',
          'email-confirm',
          'Your email addresses don’t match. Please try again.'
        )
        return false
      }
      if ($passwordInput.val().length < 8) {
        // ND TODO: enforce specific password restrictions
        Form.showErrorForField(
          'registration',
          'password',
          'Your password must be at least 8 characters.'
        )
        return
      }
      if ($passwordInput.val() !== $passwordConfirmInput.val()) {
        Form.showErrorForField(
          'registration',
          'password-confirm',
          'Your passwords don’t match. Please try again.'
        )
        return false
      }
      break
    case 2:
      if (!isValidName($firstNameInput.val())) {
        Form.showErrorForField(
          'registration',
          'first-name',
          'Please enter a valid first name.'
        )
        return false
      }
      if (!isValidName($lastNameInput.val())) {
        Form.showErrorForField(
          'registration',
          'last-name',
          'Please enter a valid last name.'
        )
        return false
      }
      if (!isPopulatedStepTwo()) {
        return false
      }
      break
    case 3:
      if ($selectedRoles.length < 1) {
        NotificationBanner.open('Please select at least one role.', 'error')
        return false
      }
      if ($selectedGrades.length < 1) {
        NotificationBanner.open('Please select at least one grade.', 'error')
        return false
      }

      // Double check that the school is validated.
      if (
        $(
          'section.registration select[name="school"] option:selected'
        ).val() === '-1'
      ) {
        NotificationBanner.open(
          'Step 2: Please select your school from the dropdown menu, or select "I don\'t see my school."',
          'error'
        )
        return false
      }
      break
  }

  return true
}

var isInstitutionModalValid = function () {
  Form.hideValidationForForm('institution-modal')
  var address = $('#institution-modal .address input').val()
  var city = $('#institution-modal .city input').val()
  var state = $('#institution-modal .state select option:selected').val()

  if (address.length < 1) {
    Form.showErrorForField(
      'institution-modal',
      'address',
      'Street Address is Required'
    )

    NotificationBanner.open('Street Address is Required', 'error')
    return false
  }

  if (city.length < 1) {
    Form.showErrorForField('institution-modal', 'city', 'City is Required')

    NotificationBanner.open('City is required', 'error')
    return false
  }

  if (state === '-1') {
    // -1 is the value for unselected.
    Form.showErrorForField('institution-modal', 'state', 'State is Required')

    NotificationBanner.open('State is required', 'error')
    return false
  }

  return true
}

var updateRegForm = function () {
  var $formSection = $('section.registration')
  const title = $('[data-panel-title]')
  title.html('<strong>Registration:</strong> Step ' + step)

  $formSection.find('.step').addClass('hide')
  $formSection
    .find(`[data-step="${step}"]`)
    .removeClass('hide')
    .find('.form__field:visible input')
    .first()
    .focus()

  var $formButtons = $('section.registration .form__footer')
  $formButtons.find('.back-btn').hide()
  $formButtons.find('.next-btn').hide()

  $formButtons.find('.finish-btn').hide()

  switch (step) {
    case 1:
      $formButtons.find('.back-btn, .next-btn').show()
      break
    case 2:
      if (isPopulatedInstitution()) {
        showPopulateInstitute() // shows user-generated info through institution modal
      } else if (isPopulatedStepTwo()) {
        showPopulatedSchoolSearch() // shows populated schoo info based on selected search result
      } else {
        showSchoolSearch()
      }
      break
    case 3:
      $formButtons.find('.back-btn, .finish-btn').show()
      break
  }
}

var submitRegForm = function (e) {
  e.preventDefault()

  if (!stepValidated()) {
    return false
  }

  if (step < 3) {
    step++
    updateRegForm()
  } else if (step === 3) {
    makeRegFormRequest()
  } else {
    console.error('Step number out of expected range')
  }
}

var TOUAccepted = function (e) {
  QuickModal.closeModal()
  makeRegFormRequest()
}

var makeRegFormRequest = function () {
  var formMap = {
    altCity: 'user.city',
    altName: 'user.institutionName',
    altState: 'user.state',
    altStreet: 'user.address',
    altType: 'altType',
    altZip: 'user.zip',
    email: 'user.emailAddress',
    emailConfirm: 'emailConfirm',
    firstName: 'user.firstName',
    lastName: 'user.lastName',
    password: 'user.password',
    passwordConfirm: 'passwordConfirm',
    school: 'user.schoolId',
    zipCode: 'zipCode'
  }

  var registrationFormData = formToObject('#registration form')
  var altFormData = formToObject('#institution-modal form')
  var formData = $.extend(registrationFormData, altFormData)

  formAppendArray(
    formData,
    'roles',
    getListOfValues('#registration form .roles button.selected')
  )
  formAppendArray(
    formData,
    'grades',
    getListOfValues('#registration form .grades-row button.selected')
  )

  for (var key in formData) {
    if (formData.hasOwnProperty(key)) {
      if (formMap.hasOwnProperty(key)) {
        formData[formMap[key]] = formData[key]
        delete formData[key]
      }
    }
  }

  formData['user.phoneNumber'] = '000-000-0000' // todo: remove
  $.ajax({
    url: CONTEXT + '/api/v1/user/register',
    data: formData,
    method: 'POST',
    dataType: 'json',
    success: function (data) {
      console.log(data)
      if (data.success) {
        showSection('almost')

        $('#almost .form-section__name').html(data.user.firstName)
      } else {
        console.log('did not receive success on register')
      }
    },
    error: function (data) {
      var response

      try {
        response = JSON.parse(data.responseText)
      } catch (e) {
        response = '<b>' + data.status + '</b>: An unknown error occurred'
      }

      NotificationBanner.open(response, 'error')
    }
  })
}

// var loadSchools = function (e) {
//   Form.hideValidationForForm('registration')
//   var zipCode = $('#registration input[name="zipCode"]').val()
//   if (zipCode.length === 0) {
//     return
//   } else if (zipCode.length !== 5) {
//     Form.showErrorForField(
//       'registration',
//       'zip-code',
//       'ZIP Code must be 5 digits'
//     )
//     return
//   }

//   var pattern = /^\d{5}$/g

//   if (!pattern.test(zipCode)) {
//     Form.showErrorForField(
//       'registration',
//       'zip-code',
//       'ZIP Code must be 5 digits'
//     )
//     return
//   }

//   $.ajax({
//     url: CONTEXT + '/api/v1/school/populateSchools',
//     data: { postalcode: zipCode },
//     method: 'POST',
//     success: updateSchoolList
//   })
// }

export const submitInstitutionModal = function (e) {
  e.preventDefault()

  if (isInstitutionModalValid()) {
    CauliflowerModal.close('institution-modal')

    showPopulateInstitute()
  }
}

var altFormClosed = function (status) {
  $('.modal .alt-form .box')
    .children()
    .appendTo('section.registration .alt-box')
  if (status !== 'done') {
    console.log('user cancelled alt-form')
    $('select[name="school"]').val(-1) // user did not complete form, ask user to select a school to prevent step 2 from validating
  }

  console.log('altFormClosed')
}

/// //////////// ///
///              ///
///              ///
///  Dev Tools   ///
///              ///
///              ///
/// //////////// ///

var DEV_DISABLE_VALIDATION = false

var DEV_SHOW_NOTIFICATION = function (s) {
  NotificationBanner.open('Testing.')
}

var DEV_TOGGLE_VALIDATION = function () {
  DEV_DISABLE_VALIDATION = !DEV_DISABLE_VALIDATION
}

export var DEV_SHOW_STEP = function (s) {
  showSection('registration')
  step = s
  updateRegForm()
}

var DEV_POP_FORM = function () {
  const fieldsAndValues = [
    ['email', 'email@email.email'],
    ['emailConfirm', 'email@email.email'],
    ['password', 'password'],
    ['passwordConfirm', 'password'],
    ['firstName', 'firstName'],
    ['lastName', 'lastName'],
    ['schoolInput', 'schoolInput']
  ]

  fieldsAndValues.map(([field, value]) => {
    getElement(`input[name="${field}"]`).value = value
  })
}

export default {
  init: init,
  show: show,
  DEV_TOGGLE_VALIDATION: DEV_TOGGLE_VALIDATION,
  DEV_SHOW_STEP: DEV_SHOW_STEP,
  DEV_POP_FORM: DEV_POP_FORM
}
