import {
  addValidationListeners,
  addSearchResultsListeners,
  addInstitutionModalListeners,
  allButtonListeners
} from './RegistrationListeners'
import { validate } from './RegistrationPresenters'
import { attachActionHandler } from '../common/variations/classroom/actions/actions'
import { getSchoolSearchControls } from './RegistrationGetters'
import { groupControlsByType } from './RegistrationGroupers'
import { DEV_SHOW_STEP } from '../Registration'
// import CauliflowerModal from '../CauliflowerModal'

// eslint-disable-next-line no-unused-vars
const __DEV__BYPASS__VALIDATION = () => {
  const { text, 'select-one': selectOne, radio } = groupControlsByType(
    getSchoolSearchControls()
  )
  text.map(i => (i.value = '53207'))

  selectOne.map(i => {
    i.options[i.options.length - 2].selected = true
  })
  radio.map(i => i.click())

  setTimeout(() => DEV_SHOW_STEP(2), 100)
}

// @flow
const init = () => {
  // __DEV__BYPASS__VALIDATION()
  attachActionHandler()
  addValidationListeners()
  addSearchResultsListeners()
  addInstitutionModalListeners()
  allButtonListeners()
  validate()
  // CauliflowerModal.open('institution-modal')
}

export default init
