import { SchoolSearchRequest } from './RegistrationTypes'
import { getAttributeFromWindow } from '../common/get/getAttribute'
import { get } from 'lodash'

const __objectToParams = (object: Object = {}) => {
  return Object.keys(object)
    .map(key => {
      const value = encodeURIComponent(get(object, key, ''))
      return [key, value].join('=')
    })
    .join('&')
}

const __buildUrl = path => {
  return getAttributeFromWindow('CONTEXT') + `${path}`
}

const __buildSchoolSearchUrl = (request: SchoolSearchRequest) => {
  const params = __objectToParams(request)
  return __buildUrl(`/api/v1/school`) + '?' + params
}

export const fetchSchools = (request: SchoolSearchRequest) => {
  const url = __buildSchoolSearchUrl(request)

  return fetch(url, {
    headers: {
      Accept: 'application/json'
    }
  }).then(res => res.json())
}
