import './scss/login.scss'

import $ from 'jquery'
import Mustache from 'mustache'
import QuickModal from './js/QuickModal'
import Form from './js/Form'
import CauliflowerModal from './js/CauliflowerModal'
import NotificationBanner from './js/NotificationBanner'
import common, {
  showSection,
  getParameterByName,
  isEmail,
  resetPasswordMessage
} from './js/common/common'
import Registration from './js/Registration'
import { getAttributeFromWindow } from './js/common/get/getAttribute'
import { getUrlParameter } from './js/common/get/getUrlParameter'
import MessageBanner from './js/MessageBanner'
// import Login from './js/Login'

var CONTEXT, userJson, destinationAfterLogin

var DEV_SHOW_LANDING = function (s) {
  loggedIn({ user: { firstName: 'DEV' }, redirectUrl: null })
}

var DEV_SHOW_SECTION = function (section) {
  showSection(section)
}

var init = function () {
  common.init()

  CauliflowerModal.init()
  MessageBanner.init()
  NotificationBanner.init()
  Registration.init()

  initStartingPage()
  checkStatusCode()
  initGenericButtons()

  // Initialize Forms
  initLoginForm()
  initForgotForm()
  initResetForm()

  var key = getParameterByName('resetPasswordKey')
  if (key.length > 0) {
    showSection('reset')
    $('section.reset .resetPasswordKey').val(key)
    $('section.reset .form__field input')
      .first()
      .focus()
  }
}

var initStartingPage = function () {
  var hash = window.location.hash || '#'

  switch (hash.slice(1)) {
    case 'password':
      goToForgot()
      break
    case 'register':
      goToRegister()
      break
    default:
      goToLogin()
      break
  }
}

var initLoginForm = function () {
  $('#login form').on('submit', login)
  $('#login input.email, #login input.password').on('keypress', function (e) {
    if (e.which === 13) {
      login(e)
    }
  })
}

var initForgotForm = function () {
  $('#forgot form').submit(submitForgot)
  $('#forgot').on('click', "[data-action='submit']", submitForgot)
  $('#forgot').on('click', "[data-action='back']", function (e) {
    history.pushState(
      '',
      document.title,
      window.location.pathname + window.location.search
    )
    showSection('login')
  })
}

var initResetForm = function () {
  $('#reset form').submit(submitReset)
  $('#reset .submit-btn').on('click', submitReset)
}

var initGenericButtons = function () {
  $('body').on('click', "[data-action='register']", goToRegister)
  $('body').on(
    'click',
    "[data-action='forgot-password'], .forgot-btn",
    goToForgot
  )
  $('body').on('click', "[data-action='accept-tou']", TOUAccepted)
  $('body').on(
    'click',
    "[data-action='resend-activation'], .resend-activation",
    function (e) {
      var email = $('[activation-email-address]').attr(
        'activation-email-address'
      )

      if (!isEmail(email)) {
        email = $('body')
          .find('.form__field.email input')
          .val()
      }

      resendActivation(e, email)
    }
  )
}

/// /////////////////
// Form Listeners
/// /////////////
// var altFormDataFilledOut = function (formData) {
//   var formDataKeys = ['address', 'city', 'state']

//   return formDataKeys.every(function (key) {
//     var value = formData[key]
//     return typeof value !== 'undefined' && formData[key] !== ''
//   })
// }

var login = function (e) {
  e.preventDefault()
  var email = $('.form__field.email input').val()
  var password = $('.form__field.password input').val()

  Form.hideValidationForForm('login')

  if (!isEmail(email)) {
    Form.showErrorForField('login', 'email', 'Please enter your email address.')
    return
  }

  if (password.length < 1) {
    Form.showErrorForField('login', 'password', 'Please enter your password.')
    return
  }

  var formData = $('section.login form').serialize()
  $.ajax({
    url: CONTEXT + '/j_spring_security_check',
    data: formData,
    method: 'POST',
    dataType: 'json',
    success: loggedIn,
    error: function (data) {
      if (data.status === 401) {
        appendVerificationEmail(email)
        NotificationBanner.open(data.responseText, 'error')
      } else {
        NotificationBanner.open('Invalid credentials', 'error')
      }
    }
  })
}

var loggedIn = function (json) {
  const CONTEXT = getAttributeFromWindow('CONTEXT')
  const redirectPath = getUrlParameter('path')
  if (json.error) {
    NotificationBanner.open(json.error, 'error')
    userJson = null
    return
  }

  userJson = json.user
  if (redirectPath) {
    destinationAfterLogin = redirectPath
  } else {
    if (typeof json.redirectUrl === 'string') {
      destinationAfterLogin = json.redirectUrl
    } else {
      destinationAfterLogin = CONTEXT + '/toolbox'
    }
  }

  if (checkIfSchoolIsVerified()) {
    goToDestination()
  }
}

var resendActivation = function (e, emailAddress) {
  e.preventDefault()

  $.ajax({
    url: CONTEXT + '/api/v1/user/resend',
    data: { email: emailAddress },
    method: 'POST',
    dataType: 'json',
    success: function (data) {
      if (data.success) {
        showSection('almost')
        $('#almost .form-section__name').html(data.name)
        $('#almost .error')
          .html(
            "You're almost there. Please check your email and select the link."
          )
          .show()
      } else {
        appendVerificationEmail(emailAddress)
        NotificationBanner.open(data.message, 'error')
      }
    },
    error: function (data) {
      appendVerificationEmail(emailAddress)
      NotificationBanner.open(data.responseText, 'error')
    }
  })
}

var verifySchool = function (e) {
  if (e) {
    e.preventDefault()
  }

  var step = 1
  var stepTitle = 'Please Verify Your School'
  var stepText =
    'This helps us ensure you have access to the licenses your school has purchased.'

  var hasSchool = userJson.schoolId !== '' && userJson.school !== null

  var $form = $('section.verifySchool form')

  var address = ''
  if (hasSchool) {
    var data = {
      name: userJson.school.name,
      streetAddress: userJson.school.addressLine1,
      city: userJson.school.addressCity,
      state: userJson.school.stateOrProvince,
      zip: userJson.school.postalcode
    }
    address = Mustache.render(
      '<p class="address"><span class="name">{{name}}</span><span class="street-address">{{streetAddress}}</span><span class="city-state-zip">{{city}}, {{state}} {{zip}}</span></p>',
      data
    )
    $('section.verifySchool .address').replaceWith(address)
  } else {
    stepTitle = 'No School Selected'
    stepText =
      "Your account is not currently associated with a school. Please select a school on the next screen in order to use your school's license."
    $form.parent().addClass('no-school')
  }
  $form.find('p.stepText').html(stepText)
  $form.find('p.title').html(stepTitle)

  $('section.verifySchool .title .form-section__name').html(userJson.firstName)

  $('section.verifySchool .step').hide()
  $('section.verifySchool .step:nth-of-type(' + step + ')').show()

  showSection('verifySchool')

  return false
}

var submitForgot = function (e) {
  e.preventDefault()
  var $input = $("#forgot input[name='email']")

  if ($input.val().length < 1) {
    Form.showErrorForField(
      'forgot',
      'email',
      'Please enter your email address.'
    )
    return
  }

  Form.hideValidationForForm('forgot')

  const displayNotificationBanner = (email) => NotificationBanner.open(resetPasswordMessage($input.val()), 'success')

  $.ajax({
    url: CONTEXT + '/api/v1/user/resetPassword',
    data: {
      emailAddress: $input.val()
    },
    method: 'POST',
    success: function (data) {
      appendVerificationEmail($input.val())
      displayNotificationBanner($input.val())
    },
    error: function (data) {
      displayNotificationBanner($input.val())
    }
  })
}

var submitReset = function (e) {
  e.preventDefault()
  var resetPasswordKey = $('#reset .resetPasswordKey').val()
  var emailAddress = $('#reset .email input').val()
  var newPassword = $('#reset .password input').val()

  if (!isEmail(emailAddress)) {
    Form.showErrorForField('reset', 'email', 'Please enter your email address.')
    return
  }

  if (newPassword.length < 8) {
    Form.showErrorForField(
      'reset',
      'password',
      'Your password must be at least 8 characters.'
    )
    return
  }

  Form.hideValidationForForm('reset')
  $.ajax({
    url: CONTEXT + '/api/v1/user/resetPassword',
    data: {
      resetPasswordKey: resetPasswordKey,
      emailAddress: emailAddress,
      newPassword: newPassword
    },
    method: 'POST',
    success: function (data) {
      showSection('login')
      NotificationBanner.open(data.responseJSON.message)
    },
    error: function (data) {
      appendVerificationEmail(emailAddress)
      NotificationBanner.open(data.responseJSON.message, 'error')
    }
  })
}

// var showResetError = function (error) {
//   $('section.reset .error')
//     .html(error)
//     .show()
// }

// var showVerifySchoolError = function (error) {
//   $('section.verifySchool .error')
//     .html(error)
//     .show()
// }

/// /////////////////
// Navigation
/// /////////////
var goToDestination = function () {
  document.location = destinationAfterLogin
}

var goToForgot = function () {
  showSection('forgot')
}

var goToLogin = function () {
  showSection('login')
}

var goToRegister = function () {
  showSection('registration')
  Registration.show()
}

// var goToReset = function (resetPasswordKey) {
//   showSection('reset')
//   $('section.reset .resetPasswordKey').val(resetPasswordKey)
// }

/// /////////////////
// Security Checks
/// /////////////

// TODO: Resolve overlap w/similar things in Registration.js
var checkIfSchoolIsVerified = function () {
  if (typeof userJson.schoolVerified !== 'undefined') {
    if (userJson.schoolVerified === false) {
      verifySchool()
      return false
    }
  }

  return true
}

/// /////////////////
// Misc Helpers
/// /////////////
var checkStatusCode = function () {
  switch (parseInt(getParameterByName('status'))) {
    case 0:
      NotificationBanner.open('Account Activated')
      break
    case 1:
      NotificationBanner.open('Account Activation Failed', 'error')
      break
    default:
      console.log('unknown statusCode or none provided')
  }
}

var TOUAccepted = function (e) {
  $.ajax({
    url: CONTEXT + '/api/v1/user/acceptTOU',
    data: { secret: 'AP3MD406' },
    method: 'POST',
    dataType: 'json',
    success: function (data) {
      var force = true
      QuickModal.closeModal(null, force)

      if (checkIfSchoolIsVerified(data)) {
        goToDestination()
      }
    },
    error: function (data) {
      var force = true
      QuickModal.closeModal(null, force)

      if (checkIfSchoolIsVerified(data)) {
        goToDestination()
      }
    }
  })
}

// Since email verification now exists in a Notification Banner (ie outside of a form), we need a place to put this email address that's more accessible
var appendVerificationEmail = function (emailAddress) {
  var $emailField = $('[activation-email-address]')

  if ($emailField.length === 0) {
    $emailField = $('<div activation-email-address=' + emailAddress + '></div>')
    $('body').append($emailField)
  }

  return $emailField
}

document.addEventListener('DOMContentLoaded', function (event) {
  CONTEXT = window.CONTEXT || ''

  userJson = null
  destinationAfterLogin = CONTEXT + '/toolbox'

  common.init()
  init()
})

export default {
  init,
  DEV_SHOW_LANDING,
  DEV_SHOW_SECTION
}
