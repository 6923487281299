import $ from 'jquery'
import { getElement } from './common/get/getElement'

// Element Templates
var $wrapper = $('<div class="cauliflower" id="message-banner-wrapper"></div>')
var $banner = $('<div class="message-banner"></div>')
var $message = $('<div class="alert"></div>')
var $messageContent = $('<div class="message-banner-content"></div>')

var init = function () {
  const bannerMessage = getBannerMessage()
  if (bannerMessage != null) {
    __open(bannerMessage.message, bannerMessage.color)
  }
}

var __open = function (message, type) {
  var $wrapper = __findOrCreateWrapper()
  var $banner = __createBanner(message, type)

  $wrapper.append($banner)
  $wrapper.show('fast')
}

var __createBanner = function (text, type) {
  var $newBanner = $banner.clone()
  var $message = __createMessage(text, type)

  $newBanner.append($message)

  return $newBanner
}

var __createMessage = function (html, type) {
  var $newMessage = $message.clone()
  var $newMessageContent = $messageContent.clone()
  var newClass = type ? 'alert-' + type : 'alert-success'

  $newMessageContent.html(html)
  $newMessage.append($newMessageContent)
  $newMessage.addClass(newClass)

  return $newMessage
}

var __findOrCreateWrapper = function () {
  var $existingWrapper = $('#message-banner-wrapper.cauliflower')

  if ($existingWrapper.length > 0) {
    $wrapper = $existingWrapper
  } else {
    $('body').prepend($wrapper)
  }

  return $wrapper
}

export const getBannerMessage = () => {
  const bannerMessage = getElement(
    'script#banner-message'
  ).innerText.trim()
  return JSON.parse(bannerMessage)
}

export default {
  init: init
}
